import React from 'react';
import { Link } from 'react-router-dom';

const RequestAccess = () => {
  return (
    <div className="container">
      <div className="card-panel">
        <h4 className="center-align">Solicitar Acesso</h4>
        <p>
          Para solicitar acesso ou resetar sua senha, entre em contato com o setor responsável pelo e-mail ou pelo telefone abaixo:
        </p>
        <ul>
        <li>
            <strong>Responsável:</strong> [Ronnie Kindreich]
          </li>
          <li>
            <strong>Telefone:</strong> [41 996408741]
          </li>
          <li>
            <strong>E-mail:</strong> [esc.rkindreich@pc.pr.gov.br]
          </li>
        </ul>
        <p>
          Clique <Link to="/">aqui</Link> para retornar à página de login.
        </p>
      </div>
    </div>
  );
};

export default RequestAccess;