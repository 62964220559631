import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';
import { FaPlus, FaEdit, FaTrash, FaDownload } from 'react-icons/fa';

function App() {
  const [formData, setFormData] = useState({
    N_LIVRO: '',
    DATA_APREENSAO: '',
    N_Procedimento: '',
    ip_boa: '',
    N_AUTOS: '',
    TIPO: '',
    marca_modelo: '',
    COR: '',
    PLACA: '',
    CHASSI: '',
    N_LAUDO: '',
    OBS: '',
    data_entrega: '',
    imagens: [],
  });

  const [registrosOriginais, setRegistrosOriginais] = useState([]);
  const [registrosFiltrados, setRegistrosFiltrados] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const modalRef = useRef(null);

  // Parte do código do arquivo App.js

const openModal = (registro) => {
  const modalWidth = 900; // largura da janela modal
  const modalHeight = 350; // altura da janela modal

  // Função para formatar data no formato dd/mm/aaaa
  const formatarDate = (dateString) => {
    if (!dateString) return ''; // Verifica se a string é vazia ou nula
    const dateObject = new Date(dateString);
    if (isNaN(dateObject.getTime())) {
      console.log("Data inválida recebida:", dateString); // Log adicional para identificar entradas inválidas
      return 'Data Inválida';
    }
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return dateObject.toLocaleDateString('pt-BR', options);
  };

  const modalWindow = window.open('', '_blank', `width=${modalWidth},height=${modalHeight}`);
  if (modalWindow) {
    modalWindow.document.write(`
      <html>
      <head>
        <style>
          body {
            font-family: Arial, sans-serif;
          }
          .modal {
            padding: 20px;
          }
          .modal-content {
            max-width: 800px;
            margin: 0 auto;
          }
          h2 {
            text-align: center;
          }
          form {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
          }
          label {
            display: block;
            margin-bottom: 10px;
          }
          input[type="text"],
          input[type="date"],
          textarea {
            width: calc(100% - 10px);
            padding: 8px;
            font-size: 14px;
            border: 1px solid #ccc;
            border-radius: 4px;
            box-sizing: border-box;
          }
          input[type="text"],
          input[type="date"] {
            width: 100%;
          }
          textarea {
            height: 100px;
            resize: vertical;
          }
          .form-group {
            width: calc(33.33% - 10px);
            margin-bottom: 10px;
          }
          .form-group:nth-child(3n + 1) {
            clear: both;
          }
          button {
            margin-top: 10px;
            padding: 10px 20px;
            font-size: 16px;
            cursor: pointer;
            background-color: #007bff;
            color: white;
            border: none;
            border-radius: 4px;
          }
          button:hover {
            background-color: #0056b3;
          }
        </style>
      </head>
      <body>
        <div class="modal">
          <div class="modal-content">
            <h2>Editar Registro</h2>
            <form id="modalForm">
            <!-- Campo oculto para numord SERIAL PRIMARY KEY-->
              <input type="hidden" name="numord" value="${registro.numord || ''}">
              <div class="form-group">
                <label>Nº do Livro:</label>
                <input type="text" name="N_LIVRO" placeholder="Número do Livro" value="${registro.n_livro || ''}">
              </div>
                              <div class="form-group">
                  <label>Data de Apreensão:</label>
                  <input type="text" name="DATA_APREENSAO" placeholder="dd/mm/aaaa" value="${formatarDate(registro.data_apreensao) || ''}">
                </div>
                                <div class="form-group">
                  <label>Nº Procedimento:</label>
                  <input type="text" name="N_Procedimento" placeholder="Número do Procedimento" value="${registro.n_procedimento || ''}">
                </div>
                <div class="form-group">
                  <label>IP-BOA:</label>
                  <input type="text" name="ip_boa" placeholder="IP-BOA" value="${registro.ip_boa || ''}">
                </div>
                <div class="form-group">
                  <label>Nº Autos:</label>
                  <input type="text" name="N_AUTOS" placeholder="Número dos Autos" value="${registro.n_autos || ''}">
                </div>
                <div class="form-group">
                  <label>Tipo:</label>
                  <input type="text" name="TIPO" placeholder="Tipo" value="${registro.TIPO || ''}">
                </div>
                <div class="form-group">
                  <label>Marca/Modelo:</label>
                  <input type="text" name="marca_modelo" placeholder="Marca e Modelo" value="${registro.marca_modelo || ''}">
                </div>
                <div class="form-group">
                  <label>Cor:</label>
                  <input type="text" name="COR" placeholder="Cor" value="${registro.cor || ''}">
                </div>
                <div class="form-group">
                  <label>Placa:</label>
                  <input type="text" name="PLACA" placeholder="Placa" value="${registro.placa || ''}">
                </div>
                <div class="form-group">
                  <label>Chassi:</label>
                  <input type="text" name="CHASSI" placeholder="Chassi" value="${registro.chassi || ''}">
                </div>
                <div class="form-group">
                  <label>Nº Laudo:</label>
                  <input type="text" name="N_LAUDO" placeholder="Número do Laudo" value="${registro.n_laudo || ''}">
                </div>
                <div class="form-group">
                  <label>OBS:</label>
                  <textarea name="OBS" placeholder="Observações">${registro.obs || ''}</textarea>
                </div>
                <div class="form-group">
                  <label>Data de Entrega:</label>
                  <input type="text" name="data_entrega" placeholder="dd/mm/aaaa" value="${formatarDate(registro.data_entrega) || ''}">
                </div>
              <!-- Restante do formulário omitido para brevidade -->
              <div>
                <!-- Ajuste os botões para chamar funções de dentro do modal -->
                <button type="button" onclick="handleSaveClick()">Salvar</button>
                <button type="button" onclick="window.close()">Sair</button>
              </div>
            </form>
          </div>
        </div>
      </body>
      </html>
    `);
  } else {
    alert('Não foi possível abrir a nova janela do modal.');
  }

  // A função handleSaveClick é chamada internamente dentro do modal
  modalWindow.handleSaveClick = () => {
    const formData = new FormData(modalWindow.document.getElementById('modalForm'));

    const updatedData = {
      numord: formData.get('numord'),
      N_LIVRO: formData.get('N_LIVRO'),
      DATA_APREENSAO: formData.get('DATA_APREENSAO'),
      N_Procedimento: formData.get('N_Procedimento'),
      ip_boa: formData.get('ip_boa'),
      N_AUTOS: formData.get('N_AUTOS'),
      TIPO: formData.get('TIPO'),
      marca_modelo: formData.get('marca_modelo'),
      COR: formData.get('COR'),
      PLACA: formData.get('PLACA'),
      CHASSI: formData.get('CHASSI'),
      N_LAUDO: formData.get('N_LAUDO'),
      OBS: formData.get('OBS'),
      data_entrega: formData.get('data_entrega'),
    };

    // Verificar se os campos obrigatórios estão preenchidos
    if (!updatedData.N_LIVRO || !updatedData.DATA_APREENSAO || !updatedData.N_Procedimento) {
      alert('Os campos Numero do Livro, DATA de APREENSAO e Número do Procedimento são obrigatórios.');
      return;
    }

    // Aqui você pode enviar os dados atualizados para o servidor, se necessário

    console.log('Dados enviados para atualização:', updatedData);
    console.log ("registro a atualizar numord = " + updatedData.numord)
    // Enviar os dados atualizados para o servidor usando Axios
    try {
      // Make a PUT request to update the record
      axios.put(`http://ronnie.dev.br:3003/registros/${updatedData.numord}`, updatedData);
      
      // Optionally handle success or inform the user
      alert('Registro atualizado com sucessooooooo.');
      // window.location.reload();
      fetchRegistros(); // Atualizar apenas os registros após o upload, sem recarregar a página

      // Close the modal
      closeModal();
      
      // Refetch the updated records
      fetchRegistros();
    } catch (error) {
      console.error('Erro ao atualizar registro:', error);
      alert('Erro ao atualizar registro.');
    }
    closeModal();
  };
};


  const closeModal = () => {
    setShowModal(false);
    setCurrentRecord(null);
  };

  const handleUpdateClick = (registro) => {
    openModal(registro);
  };

  const handleCancelClick = () => {
    closeModal();
  };

  const handleSaveClick = async (e) => {
    e.preventDefault();
    console.log ("testetttt" + e.target.elements.n_livro.value)
    // Extract updated data from the form
    const updatedData = {
      N_LIVRO: e.target.elements.N_LIVRO.value,
      DATA_APREENSAO: e.target.elements.DATA_APREENSAO.value,
      N_Procedimento: e.target.elements.N_Procedimento.value,
      ip_boa: e.target.elements.ip_boa.value,
      N_AUTOS: e.target.elements.N_AUTOS.value,
      TIPO: e.target.elements.TIPO.value,
      marca_modelo: e.target.elements.marca_modelo.value,
      COR: e.target.elements.COR.value,
      PLACA: e.target.elements.PLACA.value,
      CHASSI: e.target.elements.CHASSI.value,
      N_LAUDO: e.target.elements.N_LAUDO.value,
      OBS: e.target.elements.OBS.value,
      data_entrega: e.target.elements.data_entrega.value,
    };

    console.log ("enviado para uptate: " + updatedData)
    
    try {
      // Make a PUT request to update the record
      await axios.put(`http://ronnie.dev.br:3003/registros/${currentRecord.id}`, updatedData);
      
      // Optionally handle success or inform the user
      alert('Registro atualizado com sucesso.');
      
      // Close the modal
      closeModal();
      
      // Refetch the updated records
      fetchRegistros();
    } catch (error) {
      console.error('Erro ao atualizar registro:', error);
      alert('Erro ao atualizar registro.');
    }
    closeModal();
  };

  useEffect(() => {
    fetchRegistros();
  }, []);

  const fetchRegistros = async () => {
    try {
      const response = await axios.get('http://ronnie.dev.br:3003/registros');
      setRegistrosOriginais(response.data);
      setRegistrosFiltrados(response.data);
      // console.log('Registros recebidos:', response.data);
    } catch (error) {
      console.error('Erro ao buscar registros:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });

    if (
      name === "N_LIVRO" || name === "N_Procedimento" ||
      name === "PLACA" || name === "CHASSI" ||
      name === "N_LAUDO" || name === "OBS" ||
      name === "ip_boa" || name === "N_AUTOS" ||
      name === "TIPO" || name === "marca_modelo" ||
      name === "COR" || name === "DATA_APREENSAO" ||
      name === "data_entrega"
    ) {
      const nLivro = name === "N_LIVRO" ? value : formData.N_LIVRO;
      const nProcedimento = name === "N_Procedimento" ? value : formData.N_Procedimento;
      const placa = name === "PLACA" ? value : formData.PLACA;
      const chassi = name === "CHASSI" ? value : formData.CHASSI;
      const nLaudo = name === "N_LAUDO" ? value : formData.N_LAUDO;
      const obs = name === "OBS" ? value : formData.OBS;
      const ipBoa = name === "ip_boa" ? value : formData.ip_boa;
      const nAutos = name === "N_AUTOS" ? value : formData.N_AUTOS;
      const tipo = name === "TIPO" ? value : formData.TIPO;
      const marcaModelo = name === "marca_modelo" ? value : formData.marca_modelo;
      const cor = name === "COR" ? value : formData.COR;
      const dataApreensao = name === "DATA_APREENSAO" ? value : formData.DATA_APREENSAO || '';
      const dataEntrega = name === "data_entrega" ? value : formData.data_entrega || '';

      filterRegistros(
        nLivro, nProcedimento, placa, chassi, nLaudo, obs,
        ipBoa, nAutos, tipo, marcaModelo, cor, dataApreensao, dataEntrega
      );
    }
  };

  const filterRegistros = (nLivro, nProcedimento, placa, chassi, nLaudo, obs, ipBoa, nAutos, tipo, marcaModelo, cor, dataApreensao, dataEntrega) => {
    // console.log('Filtro data_entrega:', dataEntrega);
    const filtered = registrosOriginais.filter(registro => {
      const livro = registro.n_livro ? String(registro.n_livro).toLowerCase() : '';
      const procedimento = registro.n_procedimento ? String(registro.n_procedimento).toLowerCase() : '';
      const registroPlaca = registro.placa ? String(registro.placa).toLowerCase() : '';
      const registroChassi = registro.chassi ? String(registro.chassi).toLowerCase() : '';
      const laudo = registro.n_laudo ? String(registro.n_laudo).toLowerCase() : '';
      const observacao = registro.obs ? String(registro.obs).toLowerCase() : '';
      const ipBoaRegistro = registro.ip_boa ? String(registro.ip_boa).toLowerCase() : '';
      const autosRegistro = registro.n_autos ? String(registro.n_autos).toLowerCase() : '';
      const tipoRegistro = registro.tipo ? String(registro.tipo).toLowerCase() : '';
      const marcaModeloRegistro = registro.marca_modelo ? String(registro.marca_modelo).toLowerCase() : '';
      const corRegistro = registro.cor ? String(registro.cor).toLowerCase() : '';
      const apreensao = registro.data_apreensao ? String(registro.data_apreensao) : '';
      const entrega = registro.data_entrega ? String(registro.data_entrega) : '';

      let dataEntregaMatches = true;
      if (dataEntrega === 'comData') {
        dataEntregaMatches = entrega !== '';
      } else if (dataEntrega === 'semData') {
        dataEntregaMatches = entrega === '';
      } else {
        dataEntregaMatches = entrega.includes(dataEntrega);
      }

      // console.log('Filtro comData: ', dataEntregaMatches, ' entrega:', entrega);

      return livro.includes(nLivro.toLowerCase()) &&
        procedimento.includes(nProcedimento.toLowerCase()) &&
        registroPlaca.includes(placa.toLowerCase()) &&
        registroChassi.includes(chassi.toLowerCase()) &&
        laudo.includes(nLaudo.toLowerCase()) &&
        observacao.includes(obs.toLowerCase()) &&
        ipBoaRegistro.includes(ipBoa.toLowerCase()) &&
        autosRegistro.includes(nAutos.toLowerCase()) &&
        tipoRegistro.includes(tipo.toLowerCase()) &&
        marcaModeloRegistro.includes(marcaModelo.toLowerCase()) &&
        corRegistro.includes(cor.toLowerCase()) &&
        apreensao.includes(dataApreensao) &&
        dataEntregaMatches;
    });
    setRegistrosFiltrados(filtered);
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, imagens: Array.from(e.target.files) });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Verificação de campos obrigatórios
    const { N_LIVRO, DATA_APREENSAO, N_Procedimento } = formData;
    if (!N_LIVRO || !DATA_APREENSAO || !N_Procedimento) {
      alert('Os campos Numero do Livro, DATA de APREENSAO e Número do Procedimento são obrigatórios.');
      return;
    }

    const formDataToSend = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      if (key === 'imagens') {
        formData.imagens.forEach((imagem) => {
          formDataToSend.append('imagens', imagem);
        });
      } else {
        formDataToSend.append(key, value === '' ? null : value); // Enviar null se estiver vazio
      }
    });
    try {
      await axios.post('http://ronnie.dev.br:3003/upload', formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      alert('Upload realizado com sucesso.');
      // window.location.reload();
      fetchRegistros(); // Atualizar apenas os registros após o upload, sem recarregar a página
    } catch (error) {
      console.error('Erro ao fazer upload:', error);
      alert('Erro ao fazer upload.');
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`http://ronnie.dev.br:3003/registros/${id}`);
      alert('Registro deletado com sucesso.');
      fetchRegistros();
    } catch (error) {
      console.error('Erro ao deletar registro:', error);
      alert('Erro ao deletar registro.');
    }
  };

  const openImageInNewTab = (imageUrl) => {
    window.open(imageUrl, '_blank');
  };

  const formatDate = (dateString) => {
    if (!dateString) return ''; // Verifica se a string é vazia ou nula
    const dateObject = new Date(dateString);
    if (isNaN(dateObject.getTime())) {
      console.log("Data inválida recebida:", dateString); // Log adicional para identificar entradas inválidas
      return 'Data Inválida';
    }
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    // console.log("data lazarenta ---> " + dateObject.toLocaleDateString('pt-BR', options))
    return dateObject.toLocaleDateString('pt-BR', options);
  };

  const handleDownload = () => {
    // Filtra as colunas que não devem ser incluídas no arquivo ODS
    const dadosParaExportar = registrosFiltrados.map(registro => {
        const { caminho1, caminho2, caminho3, caminho4, numord, ...dadosRestantes } = registro;

        // Formata todas as propriedades que são datas
        Object.keys(dadosRestantes).forEach(key => {
            if (dadosRestantes[key] && typeof dadosRestantes[key] === 'string' && !isNaN(Date.parse(dadosRestantes[key]))) {
                dadosRestantes[key] = formatDate(dadosRestantes[key]);
            }
        });

        // Cria um novo objeto com chaves em caixa alta
        const dadosComChavesEmCaixaAlta = {};
        Object.keys(dadosRestantes).forEach(key => {
            dadosComChavesEmCaixaAlta[key.toUpperCase()] = dadosRestantes[key];
        });

        return dadosComChavesEmCaixaAlta;
    });

    // Cria uma nova planilha
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(dadosParaExportar);

    // Ajusta a largura das colunas automaticamente com base no conteúdo
    const colWidths = dadosParaExportar.reduce((acc, row) => {
        Object.keys(row).forEach((key, i) => {
            const value = row[key];
            const length = value ? value.toString().length : 10;
            acc[i] = Math.max(acc[i] || 20, length);
        });
        return acc;
    }, []);

    ws['!cols'] = colWidths.map(w => ({ wch: w + 2 }));

    // Ajusta a altura das linhas do cabeçalho
    ws['!rows'] = [{ hpx: 40 }];

    // Adiciona a planilha ao workbook
    XLSX.utils.book_append_sheet(wb, ws, 'Registros');

    // Define as opções de arquivo
    const wbout = XLSX.write(wb, { bookType: 'ods', type: 'binary' });

    // Cria um Blob e um link para download
    const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'registros.ods';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};

const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
};

return (
  <div style={{ maxWidth: '1200px', margin: '0 auto', padding: '10px', border: '1px solid #ccc' }}>
      <h1 style={{ textAlign: 'center', paddingBottom:'10px' }}>Veículos Apreendidos - 18ª SDP</h1>
      <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', marginBottom: '10px'}}>
            {Object.entries(formData).map(([key, value]) => (
                key !== 'data_entrega' && (
                  <div key={key} style={{ flex: '1 1 15%', marginBottom: '10px', boxSizing: 'border-box', padding: '0 10px' }}>
                    {key === 'DATA_APREENSAO' ? (
                      <label style={{ display: 'block', marginBottom: '5px' }}>
                        {key}:
                        <input
                          type="date"
                          name={key}
                          value={value}
                          onChange={handleChange}
                          style={{ width: '100%' }}
                        />
                      </label>
                    ) : key === 'imagens' ? (
                      <label style={{ display: 'block', marginBottom: '5px', padding: '5px' }}>
                        Imagens:
                        <br />
                        <input
                          type="file"
                          name={key}
                          multiple
                          onChange={handleFileChange}
                        />
                      </label>
                    ) : (
                      <label style={{ display: 'block', marginBottom: '5px' }}>
                        {key}:
                        <input
                          type="text"
                          name={key}
                          value={value}
                          onChange={handleChange}
                          style={{ width: '100%' }}
                        />
                      </label>
                    )}
                  </div>
                )
              ))}
          </div>
          <button 
              type="submit"
              style={{ fontSize: '16px', height: '30px', width: '15%' }}
          >
              <FaPlus /> Gravar Registro
          </button>
      </form>

      <h1 style={{ textAlign: 'center', marginTop: '20px' }}>Registros de Veículos</h1>

      <div style={{ overflowX: 'auto', maxHeight: '600px', marginTop: '20px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              <button onClick={handleDownload} style={{ fontSize: '16px', height: '30px', width: '15%' }}>
              <FaDownload /> Baixar Registros
              </button>

              <div style={{ display: 'flex', justifyContent: 'flex-end', width: '85%' }}>
                  <label style={{ marginBottom: '10px', padding: '0 10px' }}>
                      Filtrar por Data de Entrega:
                      <select
                          value={formData.data_entrega}
                          onChange={handleChange}
                          name="data_entrega"
                          style={{ marginLeft: 'auto' }}
                      >
                          <option value="">Todos</option>
                          <option value="comData">Com Data de Entrega</option>
                          <option value="semData">Sem Data de Entrega</option>
                      </select>
                  </label>
              </div>
          </div>

          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead style={{ backgroundColor: 'lightgreen', position: 'sticky', top: '0px', zIndex: 1 }}>
          <tr>
                      <th style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'center' }}>N_LIVRO</th>
                      <th style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'center' }}>DATA_APREENSAO</th>
                      <th style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'center' }}>N_Procedimento</th>
                      <th style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'center' }}>IP_BOA</th>
                      <th style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'center' }}>N_AUTOS</th>
                      <th style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'center' }}>TIPO</th>
                      <th style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'center' }}>MARCA_MODELO</th>
                      <th style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'center' }}>COR</th>
                      <th style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'center' }}>PLACA</th>
                      <th style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'center' }}>CHASSI</th>
                      <th style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'center' }}>N_LAUDO</th>
                      <th style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'center' }}>OBS</th>
                      <th style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'center' }}>data_entrega</th>
                      <th style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'center' }}>Imagem 1</th>
                      <th style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'center' }}>Imagem 2</th>
                      <th style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'center' }}>Imagem 3</th>
                      <th style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'center' }}>Imagem 4</th>
                      <th style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'center' }}>Atualizar</th>
                      <th style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'center' }}>Remover</th>
                  </tr>
              </thead>
              <tbody>
                  {registrosFiltrados.map((registro) => (
                      <tr key={registro.numord}>
                          <td style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'center' }}>{registro.n_livro}</td>
                          <td style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'center' }}>{formatDate(registro.data_apreensao)}</td>
                          <td style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'center' }}>{registro.n_procedimento}</td>
                          <td style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'center' }}>{registro.ip_boa}</td>
                          <td style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'center' }}>{registro.n_autos}</td>
                          <td style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'center' }}>{registro.tipo}</td>
                          <td style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'center' }}>{registro.marca_modelo}</td>
                          <td style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'center' }}>{registro.cor}</td>
                          <td style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'center' }}>{registro.placa}</td>
                          <td style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'center' }}>{registro.chassi}</td>
                          <td style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'center' }}>{registro.n_laudo}</td>
                          <td style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'center' }}>{registro.obs}</td>
                          <td style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'center' }}>{formatDate(registro.data_entrega)}</td>
                          <td style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'center' }}>
                              {registro.caminho1 ? (
                                  <img
                                      src={`http://ronnie.dev.br:3003/${registro.caminho1}`}
                                      alt={`Imagem 1`}
                                      style={{ width: '50px', height: '50px', cursor: 'pointer' }}
                                      onClick={() => openImageInNewTab(`http://ronnie.dev.br:3003/${registro.caminho1}`)}
                                  />
                              ) : null}
                          </td>
                          <td style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'center' }}>
                              {registro.caminho2 ? (
                                  <img
                                      src={`http://ronnie.dev.br:3003/${registro.caminho2}`}
                                      alt={`Imagem 2`}
                                      style={{ width: '50px', height: '50px', cursor: 'pointer' }}
                                      onClick={() => openImageInNewTab(`http://ronnie.dev.br:3003/${registro.caminho2}`)}
                                  />
                              ) : null}
                          </td>
                          <td style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'center' }}>
                              {registro.caminho3 ? (
                                  <img
                                      src={`http://ronnie.dev.br:3003/${registro.caminho3}`}
                                      alt={`Imagem 3`}
                                      style={{ width: '50px', height: '50px', cursor: 'pointer' }}
                                      onClick={() => openImageInNewTab(`http://ronnie.dev.br:3003/${registro.caminho3}`)}
                                  />
                              ) : null}
                          </td>
                          <td style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'center' }}>
                              {registro.caminho4 ? (
                                  <img
                                      src={`http://ronnie.dev.br:3003/${registro.caminho4}`}
                                      alt={`Imagem 4`}
                                      style={{ width: '50px', height: '50px', cursor: 'pointer' }}
                                      onClick={() => openImageInNewTab(`http://ronnie.dev.br:3003/${registro.caminho4}`)}
                                  />
                              ) : null}
                          </td>
                          <td style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'center' }}>
                          <button onClick={() => handleUpdateClick(registro)}><FaEdit /></button>

                          </td>
                          <td style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'center' }}>
                              <button onClick={() => handleDelete(registro.numord)}><FaTrash />Delete</button>
                          </td>                          
                      </tr>
                  ))}
              </tbody>
          </table>
      </div>
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <h2>Editar Registro</h2>
            <form onSubmit={handleSaveClick}>
              <input
                type="text"
                name="N_LIVRO"
                placeholder="Número do Livro"
                value={formData.N_LIVRO}
                onChange={handleChange}
              />
              <input
                type="date"
                name="DATA_APREENSAO"
                placeholder="DATA de APREENSAO"
                value={formData.DATA_APREENSAO}
                onChange={handleChange}
              />
              <input
                type="text"
                name="N_Procedimento"
                placeholder="Número do Procedimento"
                value={formData.N_Procedimento}
                onChange={handleChange}
              />
              <input
                type="text"
                name="ip_boa"
                placeholder="IP_BOA"
                value={formData.ip_boa}
                onChange={handleChange}
              />
              <input
                type="text"
                name="N_AUTOS"
                placeholder="N_AUTOS"
                value={formData.N_AUTOS}
                onChange={handleChange}
              />
              <input
                type="text"
                name="TIPO"
                placeholder="TIPO"
                value={formData.TIPO}
                onChange={handleChange}
              />
              <input
                type="text"
                name="marca_modelo"
                placeholder="Marca Modelo"
                value={formData.marca_modelo}
                onChange={handleChange}
              />
              <input
                type="text"
                name="COR"
                placeholder="COR"
                value={formData.COR}
                onChange={handleChange}
              />
              <input
                type="text"
                name="PLACA"
                placeholder="PLACA"
                value={formData.PLACA}
                onChange={handleChange}
              />
              <input
                type="text"
                name="CHASSI"
                placeholder="CHASSI"
                value={formData.CHASSI}
                onChange={handleChange}
              />
              <input
                type="text"
                name="N_LAUDO"
                placeholder="N_LAUDO"
                value={formData.N_LAUDO}
                onChange={handleChange}
              />
              <input
                type="text"
                name="OBS"
                placeholder="OBS"
                value={formData.OBS}
                onChange={handleChange}
              />
              <input
                type="date"
                name="data_entrega"
                placeholder="Data de Entrega"
                value={formData.data_entrega}
                onChange={handleChange}
              />
              <button type="submit">Salvar</button>
              <button type="button" onClick={handleCancelClick}>Cancelar</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;