  import React, { useState } from 'react';
  import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
  import Login from './components/Login';
  import VeiculosApreendidos from './components/VeiculosApreendidos';
  import Register from './components/Register';

  const App = () => {
    const [token, setToken] = useState('');

    const isAuthenticated = () => {
      return Boolean(token);
    };

    return (
      <div className="container">
        <Router>
          <Routes>
            <Route
              path="/"
              element={isAuthenticated() ? <Navigate to="/VeiculosApreendidos" replace /> : <Login setToken={setToken} />}
            />
            {isAuthenticated() && (
              <Route path="/Login" element={<Login />} />
            )}
            <Route path="/register" element={<Register setToken={setToken} />} />
            <Route path="/VeiculosApreendidos" element={<VeiculosApreendidos />} /> {/* Rota para VeiculosApreendidos */}
          </Routes>
        </Router>
      </div>
    );
  };

  export default App;
