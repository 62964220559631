import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import { FaUser, FaLock } from "react-icons/fa";
import "./Login.css";

const Login = ({ setToken }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async () => {
    try {
      const response = await axios.post('http://ronnie.dev.br:3003/login', { username, password });
      const token = response.data.token;
      setToken(token);
    } catch (error) {
      console.error('Erro no login:', error);
      showAlert('Erro no Login', 'Usuário e/ou senha incorretos. Tente novamente.');
    }
  };
  
  function showAlert(title, text) {
    swal({
      title: title,
      text: text,
      icon: 'error',
      button: 'OK',
    });
  }

  return (
    <div style={{ marginTop: '50px', display: 'flex', justifyContent: 'center' }}>
      <div style={{ width: '300px', border: '1px solid black', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.5)', padding: '30px', borderRadius: '5px' }}>
        <div style={{ marginBottom: '20px', textAlign: 'center' }}>
          <h2>Login Policial</h2>
        </div>
        <div className="input-field" style={{ position: 'relative', marginBottom: '20px' }}>
          <input
            type="text"
            id="username"
            style={{ width: '80%', padding: '10px 40px 10px 10px' }}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Username"
          />
          <FaUser className="icon" style={{ position: 'absolute', right: '35px', top: '50%', transform: 'translateY(-50%)' }} />
        </div>
        <div className="input-field" style={{ position: 'relative', marginBottom: '20px' }}>
          <input
            type="password"
            id="password"
            style={{ width: '80%', padding: '10px 40px 10px 10px' }}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
          />
          <FaLock className="icon" style={{ position: 'absolute', right: '35px', top: '50%', transform: 'translateY(-50%)' }} />
        </div>
        <div className="recall-forget">
          <label>
            <input type="checkbox" />
            Lembre de mim    
          </label>
          <a href="#"> <Link to="/register">Esqueceu sua senha? </Link></a>
        </div>
        <div style={{ textAlign: 'center' }}>
          <button
            style={{ padding: '10px 20px', backgroundColor: '#007bff', color: '#fff', border: 'none', cursor: 'pointer', marginTop:'10px' }}
            onClick={handleLogin}
          >
            Login
          </button>
        </div>
        <div style={{ marginTop: '20px', textAlign: 'center' }}>
          <p>
            Dúvidas e solicitações de acesso <Link to="/register">Solicitar</Link>
          </p>
        </div>
      </div>
    </div>
  );
  };


export default Login;
